import cloneDeep from 'lodash/cloneDeep';

import {
  RESULT_TYPE_COURSE,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_DEGREE_PROGRAM,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
} from '@utils/search';
import {
  RESET_SEARCH,
  SET_IS_SEARCHING,
  SET_FACETS,
  REPLACE_RESULTS,
  MERGE_RESULTS,
  IS_SEARCHING_VALUES,
  SET_MARKETING_PROMOTIONS,
} from '@data/constants/actionTypes/search';

export const initialState = {
  isSearching: IS_SEARCHING_VALUES.default,
  // The active refinements should be derived from
  // the query params.
  // This holds the possible facets and the available counts
  // as retrieved from Algolia.  Eg if the search is "Data Science"
  // what facets can be applied and still have results.
  facets: {
    // Example
    // subject: {
    //  Architecture: 2,
    //  Art & Culture: 4,
    // }
  },
  results: {
    [RESULT_TYPE_COURSE]: {
      items: [],
      pages: 0,
      total: 0,
      currentPage: 0,
    },
    [RESULT_TYPE_PROGRAM]: {
      items: [],
      pages: 0,
      total: 0,
      currentPage: 0,
    },
    [RESULT_TYPE_BOOT_CAMP]: {
      total: 0,
      items: [],
    },
    [RESULT_TYPE_DEGREE_PROGRAM]: {
      total: 0,
      items: [],
    },
    [RESULT_TYPE_EXECUTIVE_EDUCATION]: {
      total: 0,
      items: [],
    },
  },
  marketingPromotions: [],
};

const emptyKeyList = (object = {}, defaultValue = null) => (
  Object.keys(object)
    .reduce((keyList, key) => ({
      ...keyList,
      [key]: cloneDeep(defaultValue),
    }), {})
);

const search = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.value,
      };

    case SET_FACETS:
      // Maintain all possible types of facets (top level keys)
      // by getting the existing keys as empty objects.  These keys
      // are used in the Filter component, and they are expected
      // to remain stable after the first render.
      // This ends up with an object such as
      // {
      //   subject: {},
      //   availability: {},
      // ...
      // }
      // Merge in the refinement counts that have been passed in.
      return {
        ...state,
        facets: {
          ...emptyKeyList(state.facets, {}),
          ...action.value,
        },
      };

    case MERGE_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          ...action.value,
        },
      };

    case REPLACE_RESULTS:
      return {
        ...state,
        results: {
          ...initialState.results,
          ...action.value,
        },
      };

    case RESET_SEARCH:
      return {
        ...initialState,
        ...action.defaults,
        results: {
          ...initialState.results,
          ...action.defaults.results,
        },
      };

    case SET_MARKETING_PROMOTIONS:
      return {
        ...state,
        marketingPromotions: action.value,
      };

    default:
      return state;
  }
};

export default search;
