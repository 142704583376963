import { defineMessages } from 'gatsby-plugin-react-intl';

const messages = defineMessages({
  'prospectus.search.new.selected_facets.clear': {
    id: 'prospectus.search.new.selected_facets.clear',
    description: 'Label for button to clear all of the currently search filters',
    defaultMessage: 'Clear all',
  },
  'prospectus.search.new.filters.heading': {
    id: 'prospectus.search.new.filters.heading',
    description: 'Heading for the filters available to apply to the current search.',
    defaultMessage: 'All filters',
  },
  'prospectus.search.new.filters.title.subject': {
    id: 'prospectus.search.new.filters.title.subject',
    description: 'Title for the search filter dropdown for course subject',
    defaultMessage: 'Subject',
  },
  'prospectus.search.new.filters.title.skill': {
    id: 'prospectus.search.new.filters.title.skill',
    description: 'Title for the search filter dropdown for course skill',
    defaultMessage: 'Skill',
  },
  'prospectus.search.new.filters.title.partner': {
    id: 'prospectus.search.new.filters.title.partner',
    description: 'Title for the search filter dropdown for course partner',
    defaultMessage: 'Partner',
  },
  'prospectus.search.new.filters.title.program': {
    id: 'prospectus.search.new.filters.title.program',
    description: 'Title for the search filter dropdown for associated program type',
    defaultMessage: 'Program',
  },
  'prospectus.search.new.filters.title.level': {
    id: 'prospectus.search.new.filters.title.level',
    description: 'Title for the search filter dropdown for course level',
    defaultMessage: 'Level',
  },
  'prospectus.search.new.filters.title.availability': {
    id: 'prospectus.search.new.filters.title.availability',
    description: 'Title for the search filter dropdown for course availability',
    defaultMessage: 'Availability',
  },
  'prospectus.search.new.filters.title.language': {
    id: 'prospectus.search.new.filters.title.language',
    description: 'Title for the search filter dropdown for course language',
    defaultMessage: 'Language',
  },
  'prospectus.search.new.filters.title.learning_type': {
    id: 'prospectus.search.new.filters.title.learning_type',
    description: 'Title for the search filter dropdown for learning type (course or program)',
    defaultMessage: 'Learning type',
  },
  'prospectus.search.new.facets.clear-one': {
    id: 'prospectus.search.new.facets.clear-one',
    description: 'Label for button that clears one specific filter, eg "Remove the subject filter Architecture"',
    defaultMessage: 'Remove the {facet} filter {label}.',
  },
  'prospectus.search.new.facets.clear-learning-type.course': {
    id: 'prospectus.search.new.facets.clear-learning-type.course',
    description: 'Label for button that clears the course filter"',
    defaultMessage: 'Remove the learning type filter Courses.',
  },
  'prospectus.search.new.facets.clear-learning-type.program': {
    id: 'prospectus.search.new.facets.clear-learning-type.program',
    description: 'Label for button that clears the programs filter"',
    defaultMessage: 'Remove the learning type filter Programs.',
  },
  'prospectus.search.new.facets.clear-learning-type.boot-camp': {
    id: 'prospectus.search.new.facets.clear-learning-type.boot-camp',
    description: 'Label for button that clears the programs filter"',
    defaultMessage: 'Remove the filter Boot Camps.',
  },
  'prospectus.search.new.facets.clear-learning-type.executive-education': {
    id: 'prospectus.search.new.facets.clear-learning-type.executive-education',
    description: 'Label for button that clears the programs filter"',
    defaultMessage: 'Remove the filter Executive Education.',
  },
  'prospectus.search.new.facets.clear-learning-type.degree-program': {
    id: 'prospectus.search.new.facets.clear-learning-type.degree-program',
    description: 'Label for button that clears the programs filter"',
    defaultMessage: 'Remove the filter Degree Programs.',
  },
  'prospectus.search.new.facets.showless': {
    id: 'prospectus.search.new.facets.showless',
    description: 'Button to show fewer filters',
    defaultMessage: 'show less',
  },
  'prospectus.search.new.facets.none-found': {
    id: 'prospectus.search.new.facets.none-found',
    description: 'Message explaining that there are no available filters',
    defaultMessage: 'No options found.',
  },
  'prospectus.search.new.filters.applied-count': {
    id: 'prospectus.search.new.filters.applied-count',
    defaultMessage: '{totalAppliedFilters} selected',
    description: 'Text describing the total number of applied filters.',
  },
  'prospectus.search.new.filters.header.close': {
    id: 'prospectus.search.new.filters.header.close',
    description: 'Label for button to close mobile filter menu.',
    defaultMessage: 'Close filter menu.',
  },
  'prospectus.search.new.filters.close': {
    id: 'prospectus.search.new.filters.close',
    description: 'Label for button to close mobile filter menu.',
    defaultMessage: 'Done',
  },
  'prospectus.search.new.filters.learning_type.course': {
    id: 'prospectus.search.new.filters.learning_type.course',
    description: 'Label for tile indicating the courses filter is active',
    defaultMessage: 'Courses',
  },
  'prospectus.search.new.filters.learning_type.program': {
    id: 'prospectus.search.new.filters.learning_type.program',
    description: 'Label for tile indicating the programs filter is active',
    defaultMessage: 'Programs',
  },
  'prospectus.search.new.filters.learning_type.boot-camp': {
    id: 'prospectus.search.new.filters.learning_type.boot-camp',
    description: 'Label for tile indicating the boot camps filter is active',
    defaultMessage: 'Boot Camps',
  },
  'prospectus.search.new.filters.learning_type.executive-education': {
    id: 'prospectus.search.new.filters.learning_type.executive-education',
    description: 'Label for tile indicating the execuctive education filter is active',
    defaultMessage: 'Executive Education',
  },
  'prospectus.search.new.filters.learning_type.degree-program': {
    id: 'prospectus.search.new.filters.learning_type.degree-program',
    description: 'Label for tile indicating the degree program filter is active',
    defaultMessage: 'Degree Programs',
  },

  // Learning type labels

  'prospectus.search.new.filters.label.learning_type.course': {
    id: 'prospectus.search.new.filters.label.learning_type.course',
    description: 'Course learning type filter option label',
    defaultMessage: 'Courses',
  },
  'prospectus.search.new.filters.label.learning_type.boot_camp': {
    id: 'prospectus.search.new.filters.label.learning_type.boot_camp',
    description: 'Boot camp learning type filter option label',
    defaultMessage: 'Boot camps',
  },
  'prospectus.search.new.filters.label.learning_type.microbootcamp': {
    id: 'prospectus.search.new.filters.label.learning_type.microbootcamp',
    description: 'MicroBootCamp learning type filter option label',
    defaultMessage: 'MicroBootCamps',
  },
  'prospectus.search.new.filters.label.learning_type.executive_education': {
    id: 'prospectus.search.new.filters.label.learning_type.executive_education',
    description: 'Executive education learning type filter option label',
    defaultMessage: 'Executive Education',
  },
  'prospectus.search.new.filters.label.learning_type.masters': {
    id: 'prospectus.search.new.filters.label.learning_type.masters',
    description: 'Masters learning type filter option label',
    defaultMessage: 'Master’s degrees',
  },
  'prospectus.search.new.filters.label.learning_type.bachelors': {
    id: 'prospectus.search.new.filters.label.learning_type.bachelors',
    description: 'Bachelors learning type filter option label',
    defaultMessage: 'Bachelor’s degrees',
  },
  'prospectus.search.new.filters.label.learning_type.micromasters': {
    id: 'prospectus.search.new.filters.label.learning_type.micromasters',
    description: 'MicroMasters learning type filter option label',
    defaultMessage: 'MicroMasters® Program',
  },
  'prospectus.search.new.filters.label.learning_type.microbachelors': {
    id: 'prospectus.search.new.filters.label.learning_type.microbachelors',
    description: 'MicroBachelors learning type filter option label',
    defaultMessage: 'MicroBachelors® Program',
  },
  'prospectus.search.new.filters.label.learning_type.professional_certificate': {
    id: 'prospectus.search.new.filters.label.learning_type.professional_certificate',
    description: 'Professional certificate learning type filter option label',
    defaultMessage: 'Professional Certificates',
  },
  'prospectus.search.new.filters.label.learning_type.xseries': {
    id: 'prospectus.search.new.filters.label.learning_type.xseries',
    description: 'XSeries learning type filter option label',
    defaultMessage: 'XSeries',
  },
  'prospectus.search.new.filters.label.learning_type.certificate': {
    id: 'prospectus.search.new.filters.label.learning_type.certificate',
    description: 'Certificate learning type filter option label',
    defaultMessage: 'Certificate',
  },
  'prospectus.search.new.filters.label.learning_type.doctorate': {
    id: 'prospectus.search.new.filters.label.learning_type.doctorate',
    description: 'Doctorate learning type filter option label',
    defaultMessage: 'Doctorate degrees',
  },
  'prospectus.search.new.filters.label.learning_type.license': {
    id: 'prospectus.search.new.filters.label.learning_type.license',
    description: 'License learning type filter option label',
    defaultMessage: 'License',
  },

  // Learning type descriptions
  'prospectus.search.new.filters.description.learning_type.course': {
    id: 'prospectus.search.new.filters.description.learning_type.course',
    description: 'Course learning type filter option description',
    defaultMessage: 'Courses to upskill in your field',
  },
  'prospectus.search.new.filters.description.learning_type.boot_camp': {
    id: 'prospectus.search.new.filters.description.learning_type.boot_camp',
    description: 'Bootcamps learning type filter option description',
    defaultMessage: 'Intensive courses to build in-demand technical skills',
  },
  'prospectus.search.new.filters.description.learning_type.microbootcamp': {
    id: 'prospectus.search.new.filters.description.learning_type.microbootcamp',
    description: 'Micro Bootcamps learning type filter option description',
    defaultMessage: 'Stackable, short-form boot camps to learn the tech skills you need today',
  },
  'prospectus.search.new.filters.description.learning_type.executive_education': {
    id: 'prospectus.search.new.filters.description.learning_type.executive_education',
    description: 'Executive education learning type filter option description',
    defaultMessage: 'Cohort-based programs in career-critical skills',
  },
  'prospectus.search.new.filters.description.learning_type.masters': {
    id: 'prospectus.search.new.filters.description.learning_type.masters',
    description: 'Masters learning type filter option description',
    defaultMessage: 'Online graduate degrees taught by expert faculty',
  },
  'prospectus.search.new.filters.description.learning_type.bachelors': {
    id: 'prospectus.search.new.filters.description.learning_type.bachelors',
    description: 'Bachelors learning type filter option description',
    defaultMessage: 'Online undergraduate degrees taught by expert faculty',
  },
  'prospectus.search.new.filters.description.learning_type.micromasters': {
    id: 'prospectus.search.new.filters.description.learning_type.micromasters',
    description: 'Micro Masters learning type filter option description',
    defaultMessage: 'Paths to graduate degrees',
  },
  'prospectus.search.new.filters.description.learning_type.microbachelors': {
    id: 'prospectus.search.new.filters.description.learning_type.microbachelors',
    description: 'Micro Bachelors learning type filter option description',
    defaultMessage: 'Paths to undergraduate degrees',
  },
  'prospectus.search.new.filters.description.learning_type.professional_certificate': {
    id: 'prospectus.search.new.filters.description.learning_type.professional_certificate',
    description: 'Professional Certificate learning type filter option description',
    defaultMessage: 'Curated series of courses in high-demand fields',
  },
  'prospectus.search.new.filters.description.learning_type.xseries': {
    id: 'prospectus.search.new.filters.description.learning_type.xseries',
    description: 'XSeries learning type filter option description',
    defaultMessage: 'Curated series of courses to develop deep knowledge in popular subjects',
  },
  'prospectus.search.new.filters.description.learning_type.certificate_courses': {
    id: 'prospectus.search.new.filters.description.learning_type.certificate_courses',
    description: 'Certificate courses learning type filter option description',
    defaultMessage: 'Curated series of courses in high-demand fields. 8 - 16 weeks',
  },
  'prospectus.search.new.filters.description.learning_type.paths_to_degrees': {
    id: 'prospectus.search.new.filters.description.learning_type.paths_to_degrees',
    description: 'Paths to degrees learning type filter option description',
    defaultMessage: 'Paths to undergraduate and graduate degrees. 2 - 6 months',
  },
  'prospectus.search.new.filters.description.learning_type.degrees': {
    id: 'prospectus.search.new.filters.description.learning_type.degrees',
    description: 'Degrees learning type filter option description',
    defaultMessage: 'Online degrees taught by expert faculty. 1 - 6 years',
  },
});

export default messages;
