import { AllFiltersType } from '@data/types/filters';
import {
  parseVerticalFiltersFacets,
} from '@utils/search';
import filterMessages from '@prospectus/search-page/components/RefinementFilters/messages';
import { FILTER_ACTION_TYPES } from '../constants/actionTypes/filters';

// Options for each category are retrieved from Algolia in SearchPage component
export const initialState: AllFiltersType = {
  subject: {
    category: 'subject',
    numOptionsSelected: 0,
    categoryLabel: 'Subject',
    maxVisible: 5,
    options: {},
  },
  'skills.skill': {
    category: 'skills.skill',
    numOptionsSelected: 0,
    categoryLabel: 'Skill',
    maxVisible: 5,
    options: {},
  },
  partner: {
    category: 'partner',
    numOptionsSelected: 0,
    categoryLabel: 'School & Partner',
    maxVisible: 4,
    options: {},
  },
  learning_type: {
    category: 'learning_type',
    numOptionsSelected: 0,
    categoryLabel: 'Learning_type',
    maxVisible: 5,
    options: {},
  },
  learning_type_exp: {
    category: 'learning_type_exp',
    numOptionsSelected: 0,
    categoryLabel: 'Learning_type',
    maxVisible: 5,
    options: {},
  },
  level: {
    category: 'level',
    numOptionsSelected: 0,
    categoryLabel: 'Level',
    maxVisible: 3,
    options: {},
  },
  language: {
    category: 'language',
    numOptionsSelected: 0,
    categoryLabel: 'Language',
    maxVisible: 3,
    options: {},
  },
  availability: {
    category: 'availability',
    numOptionsSelected: 0,
    categoryLabel: 'Availability',
    maxVisible: 2,
    options: {},
  },
};

const filterReducer = (
  state = initialState,
  action = {
    type: '',
    payload: {
      category: '',
      option: '',
      value: '',
      initialOptions: { },
      currentRefinements: {},
      intl: {},
      parsedFacets: {},
    },
  },
) => {
  const {
    ADD_CATEGORIES,
    SET_CATEGORY,
    TOGGLE_OPTION,
    CLEAR_FORM,
    UPDATE_FILTERS,
    CLEAR_ALL_FILTERS,
    PARSE_AND_UPDATE_FILTERS,
  } = FILTER_ACTION_TYPES;

  switch (action?.type) {
    case ADD_CATEGORIES: {
      // initialize categories here but only if we request to algolia
      const { category, initialOptions } = action.payload;

      // get the initial options?
      const optionValues = Object.keys(initialOptions).reduce((options, optionName) => {
        // eslint-disable-next-line no-param-reassign
        options[optionName] = {
          ...options[optionName],
          selected: false,
        };
        return options;
      }, {});

      const newCategoryState = {
        ...initialState,
        category,
        options: { ...initialState, ...optionValues },
      };
      return {
        ...state,
        [category]: newCategoryState,
      };
    }

    case SET_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        [category]: {
          ...state[category],
          category: action?.payload.value,
        },
      };
    }

    case TOGGLE_OPTION: {
      const { category, option } = action.payload;
      const updatedOptions = {
        ...state[category].options,
        [option]: {
          ...state[category].options[option],
          selected: !state[category].options[option].selected,
        },
      };

      const updatedState = {
        ...state,
        [category]: {
          ...state[category],
          options: updatedOptions,
        },
      };

      // calculate numOptionsSelected for the category (calculate the number of options toggled to true)
      const numOptionsSelected = Object.values(updatedOptions)
        .filter((value: { selected: boolean }) => value.selected === true).length;

      return {
        ...updatedState,
        [category]: {
          ...updatedState[category],
          numOptionsSelected,
        },
      };
    }

    case UPDATE_FILTERS:
    {
      const updatedKeys = {};
      Object.keys(action.payload).forEach((category) => {
        updatedKeys[category] = {
          ...state[category],
          options: {
            // ...state[category].options -> Commenting to avoid displaying options that would yield no results
            ...action.payload[category].options,
          },
        };
      });
      return {
        ...state,
        ...updatedKeys,
      };
    }

    case PARSE_AND_UPDATE_FILTERS:
    {
      const { currentRefinements, intl, parsedFacets } = action.payload;
      const parsedFilters = parseVerticalFiltersFacets(
        { ...state },
        { ...currentRefinements },
        filterMessages,
        intl,
        parsedFacets,
      );
      const updatedKeys = {};
      Object.keys(parsedFilters).forEach((category) => {
        updatedKeys[category] = {
          ...state[category],
          options: {
            ...parsedFilters[category].options,
          },
        };
      });
      return {
        ...state,
        ...updatedKeys,
      };
    }

    case CLEAR_ALL_FILTERS:
    {
      // set all options to false
      const updatedKeys = {};
      Object.keys(state).forEach((category) => {
        const updatedOptions = {};
        Object.keys(state[category].options).forEach((option) => {
          updatedOptions[option] = {
            ...state[category].options[option],
            selected: false,
          };
        });
        updatedKeys[category] = {
          ...state[category],
          options: updatedOptions,
          numOptionsSelected: 0,
        };
      });

      return {
        ...state,
        ...updatedKeys,
      };
    }

    case CLEAR_FORM:
      return initialState;

    default:
      return state;
  }
};

export default filterReducer;
