export const RESET_REFINEMENTS = 'RESET_REFINEMENTS';
export const RESET_SEARCH = 'RESET_SEARCH';

export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const REPLACE_RESULTS = 'REPLACE_RESULTS';
export const MERGE_RESULTS = 'MERGE_RESULTS';
export const SET_FACETS = 'SET_FACETS';
export const SET_MARKETING_PROMOTIONS = 'SET_MARKETING_PROMOTIONS';

export const IS_SEARCHING_VALUES = { default: undefined, searching: true, done: false };
