import { combineReducers } from 'redux';

import activeCourseRun from './activeCourseRun';
import autoComplete from './autoComplete';
import showBootCampNavLink from './bootCamp';
import enrollment from './enrollment';
import enrollmentStoreFront from './enrollmentStorefront';
import enterpriseUser from './enterprise';
import {
  userEnrollments,
  userEnterprisePortalLinks,
  userEntitlements,
  userLanguage,
  userLoggedIn,
  userCurrency,
} from './user';
import instructorData from './person';
import player from './videoPlayer';
import search from './search';
import accordions from './accordions';
import degreePage from './degreePage';
import currentPage from './currentPage';
import filters from './filters';
import modal from './modal';
import mobileNav from './mobileNav';

const rootReducer = combineReducers({
  activeCourseRun,
  autoComplete,
  showBootCampNavLink,
  enrollment,
  enrollmentStoreFront,
  player,
  search,
  userEnrollments,
  userEnterprisePortalLinks,
  userEntitlements,
  userLanguage,
  userLoggedIn,
  userCurrency,
  instructorData,
  enterpriseUser,
  accordions,
  degreePage,
  currentPage,
  filters,
  modal,
  mobileNav,
});

export default rootReducer;
