import {
  ENROLL_SUBMISSION_ERROR,
  ENROLL_SUBMISSION_SUCCESS,
} from '@src/data/constants/actionTypes/enrollmentStorefront';

const initialState = {
  value: '',
  error: false,
  enrolled: false,
};

const enrollmentStorefront = (state = initialState, action = { type: '', value: '' }) => {
  switch (action.type) {
    case ENROLL_SUBMISSION_ERROR:
      return {
        value: action.value,
        error: true,
        enrolled: false,
      };

    case ENROLL_SUBMISSION_SUCCESS:
      return {
        value: action.value,
        error: false,
        enrolled: true,
      };

    default:
      return state;
  }
};

export default enrollmentStorefront;
