const contentModel = require('../build-utils/helpers/contentfulContentModel');

/* eslint-disable import/extensions, import/no-unresolved */
const MAX_PAGES_DISPLAYED = 5;
const CARDS_PER_PAGE = 24;
const ALL_TAB_PRODUCTS_LENGTH = 4;
const MIN_CHARS_FOR_AUTOCOMPLETE = 3;
const MAX_RESULTS_FOR_AUTOCOMPLETE = 10;
const MAX_BOOT_CAMPS_TO_FETCH = 2;
const MAX_EE_COURSES_TO_FETCH = 2;

const SHARED_QUERY_ATTRIBUTES = [
  'marketing_url',
  'objectID',
  'organization_short_code_override',
  'owners',
  'product',
  'title',
  'uuid',
  'program_type',
  'external_url',
  'product_source',
];

// page types
const PRE_QUERY = 'pre-query';
const FIRST_LEVEL_RESULTS = 'first-level-results';
const SECOND_LEVEL_RESULTS = 'second-level-results';
const SEARCH = 'search';

// quiz match
const QUIZ_MATCH_PARAM = 'quiz_match';

// result types
// @todo use existing Course Discovery Constants
const RESULT_TYPE_NONE = 'none';
const RESULT_TYPE_COURSE = 'course';
const RESULT_TYPE_PROGRAM = 'program';
const RESULT_TYPE_ANY = 'any';
const RESULT_TYPE_EXECUTIVE_EDUCATION = 'executive-education';
const RESULT_TYPE_BOOT_CAMP = 'boot-camp';
const RESULT_TYPE_DEGREE_PROGRAM = 'degree-program';
const PRODUCT_TYPES = [
  RESULT_TYPE_COURSE,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_DEGREE_PROGRAM,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
];
const PRODUCT_TYPES_2U = [
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_DEGREE_PROGRAM,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
];

const ALL_AVAILABLE_FACETS = ['availability', 'level', 'language', 'partner', 'program_type', 'learning_type', 'skills.skill', 'subject'];
const FACETS_FOR_LEARNING_TYPE_EXPERIMENT = [...ALL_AVAILABLE_FACETS, 'learning_type_exp'];
// learning_type_exp is used in experiment WS-4468
const ALL_EXPECTED_SEARCH_PARAMETERS = [...FACETS_FOR_LEARNING_TYPE_EXPERIMENT, 'q', 'tab', 'page'];
const ALL_VALID_PARAMETERS_TO_RETAIN = [
  'consolidateFilters',
  QUIZ_MATCH_PARAM,
  'device',
];

// breadcrumb levels
const PRE_QUERY_BREADCRUMBS = 'pre-query-breadcrumbs';
const FIRST_LEVEL_BREADCRUMBS = 'first-level-breadcrumbs';
const SECOND_LEVEL_BREADCRUMBS = 'second-level-breadcrumbs';

const DEFAULT_ALGOLIA_HIGHLIGHT_PRE_TAG = 'ais-highlight-0000000000';
const DEFAULT_ALGOLIA_HIGHLIGHT_POST_TAG = 'ais-highlight-0000000001';
const SEARCH_PATH = 'search';

const FIRST_LEVEL_RESULTS_PRODUCTS_ORDERED = [
  RESULT_TYPE_COURSE,
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_DEGREE_PROGRAM,
];

const LEARNING_TYPE_FILTER_RANKS = {
  Course: 0,
  'Certificate courses': 1,
  'Boot Camp': 2,
  MicroBootCamp: 3,
  'Paths to degrees': 4,
  Degrees: 5,
  'Executive Education': 6,
  Masters: 7,
  Bachelors: 8,
  MicroMasters: 9,
  MicroBachelors: 10,
  'Professional Certificate': 11,
  XSeries: 12,
  Certificate: 13,
  Doctorate: 14,
  License: 15,
};

const getIntlSearchPath = (path = '', langCode = 'en', isWebView = false, inSecurePreview = false) => {
  const langPart = langCode === 'es' ? 'es/' : '';
  const securePreviewPart = inSecurePreview ? 'secure-preview/' : '';
  // webview/search webpage was get rid of, the apps are running webview/v2
  const webViewPart = isWebView ? 'webview/v2/' : '';
  return langPart + securePreviewPart + webViewPart + path;
};

/**
 * Returns an array of all currently applied search refinements (facets)
 * for a particular attribute
 */
const getRefinementsByAttribute = facet => facet.items.reduce((acc, item) => {
  acc.push({ ...item, attribute: facet.attribute });
  return acc;
}, []);

/**
 * Returns an array of all currently applied search refinements (facets)
 * across all attributes
 */
const getRefinementList = (items) => {
  const list = items.reduce((acc, facet) => {
    acc.push(getRefinementsByAttribute(facet));
    return acc;
  }, []);

  if (list.length > 0) {
    return list.flat();
  }

  return [];
};

const getPageTypeFromUrl = (params = new URLSearchParams()) => {
  const productType = params.get('tab');
  const hasActiveTab = productType && PRODUCT_TYPES.includes(productType.toLowerCase());
  if (hasActiveTab) {
    return SECOND_LEVEL_RESULTS;
  }

  const hasActiveSearch = Array.from(params.keys())
    .filter(key => ALL_EXPECTED_SEARCH_PARAMETERS.includes(key))
    .length;
  if (hasActiveSearch) {
    return FIRST_LEVEL_RESULTS;
  }

  return PRE_QUERY;
};

const getResultTypeFromUrl = (params = new URLSearchParams()) => {
  const productType = params.get('tab');
  if (productType && PRODUCT_TYPES.includes(productType.toLowerCase())) {
    return productType.toLowerCase();
  }
  return RESULT_TYPE_NONE;
};

const getCurrentBreadcrumbType = (params = new URLSearchParams()) => {
  const types = {
    [PRE_QUERY]: PRE_QUERY_BREADCRUMBS,
    [FIRST_LEVEL_RESULTS]: FIRST_LEVEL_BREADCRUMBS,
    [SECOND_LEVEL_RESULTS]: SECOND_LEVEL_BREADCRUMBS,
  };

  const query = params.has('q') ? params.get('q') : '';
  const pageType = getPageTypeFromUrl(params);

  // If the user is on the second level page and there is
  // no query, the breadcrumbs should point back to the prequery
  // page.
  const type = query === '' && pageType === SECOND_LEVEL_RESULTS
    ? FIRST_LEVEL_RESULTS
    : pageType;

  return types[type];
};

const createSearchResultUrl = (indexName, basePath, queryID, position) => {
  const indexParam = `index=${indexName}`;
  const queryIDParam = queryID ? `&queryID=${queryID}` : '';
  const positionParam = position ? `&position=${position}` : '';
  return `${basePath}?${indexParam}${queryIDParam}${positionParam}`;
};

const processAutocompleteItems = (allProductItems, execEdItems, bootCampItems) => {
  // we need to filter out potential duplicates if some EE or Boot Camps products were returned in both queries
  const execEdIds = execEdItems.map(item => item.uuid);
  const bootCampIds = bootCampItems.map(item => item.uuid);
  const filteredAllProducts = allProductItems
    .filter(item => !execEdIds.includes(item.uuid)
      && !bootCampIds.includes(item.uuid));

  const products = [...execEdItems, ...bootCampItems, ...filteredAllProducts];
  // in case we actually got 2 ee courses and 2 boot camps and 10 other products
  // we now need to make sure we show at max 10 suggestions instead of showing all 14
  return products.slice(0, 10);
};

const defaultAutocompleteResultsObject = {
  products: [],
};

const autocompleteSearch = (
  client,
  query,
  setAutocompleteResults,
  setAutocompleteOpen,
  indexName,
  geotargetingEnabled,
  userCountry,
) => {
  const getProductQueryParams = (product, hitsPerPage = MAX_RESULTS_FOR_AUTOCOMPLETE) => {
    let filters = product ? `product:"${product}"` : '';

    if (geotargetingEnabled && userCountry) {
      const locationRestrictionFilter = (
        `NOT blocked_in:"${userCountry}" AND (allowed_in:"null" OR allowed_in:"${userCountry}")`
      );
      filters = filters ? `${filters} AND ${locationRestrictionFilter}` : locationRestrictionFilter;
    }

    return {
      indexName,
      query,
      params: {
        hitsPerPage,
        filters,
        attributesToRetrieve: SHARED_QUERY_ATTRIBUTES,
        clickAnalytics: true,
        highlightPreTag: `${DEFAULT_ALGOLIA_HIGHLIGHT_PRE_TAG}`,
        highlightPostTag: `${DEFAULT_ALGOLIA_HIGHLIGHT_POST_TAG}`,
      },
    };
  };

  const queries = [
    getProductQueryParams(),
    getProductQueryParams('Executive Education', MAX_EE_COURSES_TO_FETCH),
    getProductQueryParams('Boot Camp', MAX_BOOT_CAMPS_TO_FETCH),
  ];

  client.multipleQueries(queries).then(({ results }) => {
    // final list should consists of 2 ee courses, 2 boot camps, and 6 any items
    const allProductItems = results[0].hits.map(item => ({ ...item, queryID: results[0].queryID }));
    const execEdItems = results[1].hits.map(item => ({ ...item, queryID: results[1].queryID }));
    const bootCampItems = results[2].hits.map(item => ({ ...item, queryID: results[2].queryID }));

    const products = processAutocompleteItems(allProductItems, execEdItems, bootCampItems);

    const autocompleteResults = {
      products,
    };

    setAutocompleteResults(autocompleteResults);
    const resultsFlattened = results.reduce((previous, current) => previous.concat(current.hits), []);

    if (resultsFlattened.length > 0) {
      setAutocompleteOpen(true);
    } else {
      setAutocompleteOpen(false);
    }
  });
};

const highlightMatch = ({ title: { matchLevel, value } }, title) => {
  if (matchLevel === 'none') {
    return title;
  }
  return value
    .replaceAll(DEFAULT_ALGOLIA_HIGHLIGHT_PRE_TAG, '<b>')
    .replaceAll(DEFAULT_ALGOLIA_HIGHLIGHT_POST_TAG, '</b>');
};

const isValidParam = (key, maintain = []) => {
  const isUtmQueryParam = key.toLowerCase().slice(0, 3) === 'utm';
  return ALL_EXPECTED_SEARCH_PARAMETERS.includes(key)
    || ALL_VALID_PARAMETERS_TO_RETAIN.includes(key)
    || isUtmQueryParam
    || maintain.includes(key);
};

const hasExtraneousParams = (searchUrlParamString, maintain = []) => {
  const urlSearchParams = new URLSearchParams(searchUrlParamString);
  const allCurrentUrlParamKeys = Array.from(urlSearchParams.keys());

  // update required to remove any params that are not related to search state or utm
  return allCurrentUrlParamKeys.some((key) => !isValidParam(key, maintain));
};

// Add a query parameter to a URL, or replace an existing one with the same name
const addQueryParams = (url, newParams) => {
  if (!newParams || newParams.length === 0) {
    // no changes needed
    return url;
  }

  const isAbsolute = url.startsWith('http');
  const prefix = 'https://example.com';
  const urlObj = isAbsolute ? new URL(url) : new URL(url, prefix);
  const params = urlObj.searchParams;
  Object.entries(newParams).forEach(([key, value]) => {
    params.set(key, value);
  });

  if (!isAbsolute) {
    return `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
  }
  return urlObj.toString();
};

const addSearchDeviceParam = (url, webview) => {
  const newParams = {};
  if (webview) {
    newParams.device = 'app';
  }

  return addQueryParams(url, newParams);
};

const getAutoCompletePreQuery = (contentfulNodes) => {
  const processCourses = (item) => item.courses.map(({ json, ...course }) => ({
    ...course, ...json, cardType: 'course', product: 'Course', externalUrl: item.additionalMetadata?.externalUrl,
  }));

  const processPrograms = (item) => item.programs.map((program) => ({ ...program, cardType: 'program', externalUrl: item.additionalMetadata?.externalUrl }));

  return contentfulNodes.reduce((acc, item) => {
    acc[item.pageType] = {
      products: [...processCourses(item), ...processPrograms(item)],
      popularSearchTerms: item.popularSearchTerms,
    };
    return acc;
  }, {});
};

const parseVerticalFiltersFacets = (
  verticalFilters,
  currentRefinements,
  filterMessages,
  intl,
  parsedResults = {},
) => {
  const updatedFilters = {};
  Object.keys(parsedResults).forEach((categoryKey) => {
    const keyOptions = {};
    const optionKeys = Object.keys(parsedResults[categoryKey]);

    if (['learning_type', 'learning_type_exp'].includes(categoryKey)) {
      const options = optionKeys.map(optionKey => ({
        key: optionKey,
        rank: LEARNING_TYPE_FILTER_RANKS[optionKey],
      }));

      options.forEach((option) => {
        if (option) {
          let description = '';
          let label = '';

          if (intl) {
            const intlOptionKey = option?.key.split(' ')?.join('_')?.toLowerCase();
            const descriptionKey = `prospectus.search.new.filters.description.learning_type.${intlOptionKey}`;
            if (filterMessages[descriptionKey]) {
              description = intl.formatMessage(filterMessages[descriptionKey]);
            }
            const labelKey = `prospectus.search.new.filters.label.learning_type.${intlOptionKey}`;
            if (filterMessages[labelKey]) {
              label = intl.formatMessage(filterMessages[labelKey]);
            }
          }

          keyOptions[option.key] = {
            selected: verticalFilters[categoryKey]?.options[option.key]?.selected
              || (currentRefinements[categoryKey] && currentRefinements[categoryKey].includes(option.key))
              || false,
            description,
            label,
            rank: option.rank,
          };
        }
      });
    } else {
      optionKeys.forEach((option) => {
        const descriptionKey = `prospectus.search.new.filters.description.${categoryKey?.toLowerCase()}.${option?.split(' ')?.join('_')?.toLowerCase()}`;
        const description = filterMessages[descriptionKey] && intl ? intl?.formatMessage(filterMessages[descriptionKey]) : '';
        keyOptions[option] = {
          selected: verticalFilters[categoryKey]?.options[option]?.selected
            || (currentRefinements[categoryKey] && currentRefinements[categoryKey].includes(option))
            || false,
          description,
        };
      });
    }

    updatedFilters[categoryKey] = {
      options: keyOptions,
    };
  });

  return updatedFilters;
};

const getQueryParams = (queryString) => {
  const params = new URLSearchParams(queryString);
  return Array.from(params.entries()).reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};

const searchPageModules = `
  ... on ContentfulTextModule {
    ${contentModel.textModule}
  }
  `;

module.exports = {
  addSearchDeviceParam,
  getPageTypeFromUrl,
  getRefinementsByAttribute,
  getRefinementList,
  getResultTypeFromUrl,
  getCurrentBreadcrumbType,
  getIntlSearchPath,
  isValidParam,
  createSearchResultUrl,
  addQueryParams,
  MAX_PAGES_DISPLAYED,
  CARDS_PER_PAGE,
  ALL_TAB_PRODUCTS_LENGTH,
  MIN_CHARS_FOR_AUTOCOMPLETE,
  DEFAULT_ALGOLIA_HIGHLIGHT_PRE_TAG,
  DEFAULT_ALGOLIA_HIGHLIGHT_POST_TAG,
  SEARCH_PATH,
  PRE_QUERY,
  QUIZ_MATCH_PARAM,
  FIRST_LEVEL_RESULTS,
  SECOND_LEVEL_RESULTS,
  defaultAutocompleteResultsObject,
  autocompleteSearch,
  highlightMatch,
  hasExtraneousParams,
  RESULT_TYPE_NONE,
  RESULT_TYPE_COURSE,
  RESULT_TYPE_PROGRAM,
  RESULT_TYPE_ANY,
  RESULT_TYPE_EXECUTIVE_EDUCATION,
  RESULT_TYPE_BOOT_CAMP,
  RESULT_TYPE_DEGREE_PROGRAM,
  PRODUCT_TYPES,
  PRODUCT_TYPES_2U,
  ALL_AVAILABLE_FACETS,
  FACETS_FOR_LEARNING_TYPE_EXPERIMENT,
  ALL_EXPECTED_SEARCH_PARAMETERS,
  PRE_QUERY_BREADCRUMBS,
  FIRST_LEVEL_BREADCRUMBS,
  SECOND_LEVEL_BREADCRUMBS,
  SEARCH,
  FIRST_LEVEL_RESULTS_PRODUCTS_ORDERED,
  getAutoCompletePreQuery,
  parseVerticalFiltersFacets,
  searchPageModules,
  getQueryParams,
};
