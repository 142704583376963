import { MOBILE_NAV_BAR_ACTION_TYPES } from '../constants/actionTypes/mobileNavBar';

const initialState = {
  mobileNavIsOpen: false,
  mobileSearchbarIsOpen: false,
};

const mobileNavReducer = (state = initialState, action = { type: '', payload: { isOpen: false } }) => {
  switch (action.type) {
    case MOBILE_NAV_BAR_ACTION_TYPES.SET_MOBILE_NAV_IS_OPEN:
      return { ...state, mobileNavIsOpen: action.payload.isOpen };
    case MOBILE_NAV_BAR_ACTION_TYPES.SET_MOBILE_SEARCHBAR_OPEN:
      return { ...state, mobileSearchbarIsOpen: action.payload.isOpen };
    default:
      return state;
  }
};

export default mobileNavReducer;
