const contentfulImage = `
  ... on ContentfulAsset {
    id
    url
    width
    height
    description
    title
  }
`;

const footnote = `
  id
  __typename
  contentful_id
  text {
    raw
  }
`;

const cardListModule = `
  name
  title
  subtitle
  cardList
  cta {
    label
    url
    isExternal
  }
  programType
  theme
  isScrollable
`;

const awards = `
  orientation
  englishAltText
  spanishAltText
  englishImage {
    ${contentfulImage}
  }
  spanishImage {
    ${contentfulImage}
  }
`;

const catalogPageCustomization = `
  uuid
  name
  pageType
  retiringSoon
  awards {
    ${awards}
  }
  customSeoFields {
    pageTitle
    pageDescription {
      pageDescription
    }
    robotsMetaTagContent
    languageCode
  }
  customMetaTags {
    name
    content
    languageCode
  }
  h1Override
  lastBreadcrumbTitle
  lastBreadcrumbUrl
  learnPage {
    slug
    title
  }
  aceLink
`;

const emailFormModule = `
  headerText
  headerEmphasis
  subheading
  buttonText
  hubspotFormId
  analyticsEvent
  theme
`;

const executive = `
  name
  id
  bio {
    raw
  }
  image {
    ${contentfulImage}
  }
  position
`;

const footnoteModule = `
  id
`;

const iframeModule = `
  embeddedIframe {
    raw
  }
  iframeHeight
`;

const factoids = `
  id
  label
  heading
  text {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
`;

const facultyModule = `
  id
  facultyList {
    id
    name
    title
    portrait {
      ${contentfulImage}
    }
  }
`;

const faq = `
  id
  question
  internal {
    type
  }
  answerRichText {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
`;

const featuredProductsModule = `
  id
  heading
  introRichText {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
  introduction {
    introduction
  }
  productList {
    textListItems {
      header
      description {
        raw
        references {
          ... on ContentfulFootnote {
            ${footnote}
          }
        }
      }
    }
  }
  theme
`;

const textLink = `
  label
  url
  isExternal
`;

const heroInternal = `
  title {
    raw
  }
  subtitle
  imageAltText
  theme
  appearance
  backgroundImageSmall {
    ${contentfulImage}
  }
  backgroundImageMedium {
    ${contentfulImage}
  }
  backgroundImageLarge {
    ${contentfulImage}
  }
  cta {
    ${textLink}
  }
  cta2 {
    ${textLink}
  }
  asset {
    title
    description
    ${contentfulImage}
  }
  videoUrl
  ctaPosition
`;

const hero = `
  hero {
    ${heroInternal}
  }
`;

const iconListItem = `
  title
  description
  swapOrderOfTitleAndIcon
  centerIconAndText
  icon {
    ${contentfulImage}
  }
  link
`;

const iconListModule = `
  title
  theme
  display
  iconList {
    ${iconListItem}
  }
`;

const stickyNavEntryModule = `
  id
  linkText
`;

const link = `
  ${textLink}
  altText
  image {
    ${contentfulImage}
  }
`;

const carouselModule = `
  heading
  headingLink
  introduction {
    raw
  }
  contentType
  links {
    ${link}
  }
  images {
    ${contentfulImage}
  }
  partnerList
  lastEntryLink {
    ${textLink}
  }
`;

const marketingBannerInternal = `
  title
  shortDescription
  detailedDescription {
    raw
  }
  link {
    ${textLink}
  }
  theme
  backgroundImage {
    ${contentfulImage}
  }
  imageAlignment
  image {
    ${contentfulImage}
  }
  targetCountry
  bannerImage {
    ${contentfulImage}
  }
`;

const marketingBanner = `
  marketingBanner {
    ${marketingBannerInternal}
  }
`;

const accordionTextModule = `
  title
  titleEmphasis
  introduction {
    raw
  }
  accordionItemsHeading {
    raw
  }
  accordionItems {
    ${faq}
  }
  usage
  theme
  displayEnrollButton
  enrollButtonUrlOverride
`;

/**
 * need to alias the body because there
 * are modules, that have body as a String
 * instead of a long text object
 */
const mediaModule = `
  bodyLong: body {
    body
  }
  bodyRichText {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
  theme
  orientation
  title
  titleRichText {
    raw
  }
  titleHeadingLevel
  appearance
  media {
    ${contentfulImage}
  }
  videoUrl
  cta {
    ${textLink}
  }
  cta2 {
    ${textLink}
  }
  cta3 {
    ${textLink}
  }
  smallImage
  appearance
  flexPageCtaButtonText
`;

const partnershipModule = `
  ctaLabel
  headingText {
    raw
  }
  ctaUrl
  bodyText {
    raw
  }
  logo {
    ${contentfulImage}
  }
`;

const priceModule = `
  theme
  heading
  firstPriceLabel
  firstPriceValue
  secondPriceLabel
  secondPriceValue
  icons {
    ... on ContentfulIconListItem {
      id
      ${iconListItem}
    }
  }
  disclaimerTitle
  disclaimerText {
    disclaimerText
  }
  cta {
    ... on ContentfulLink {
      ${link}
    }
  }
`;

const productCarouselModule = `
  title
  productLineKey
  subcategories {
    ${cardListModule}
  }
  ctaUrl
`;

const productDetailHero = `
  hero {
    leadFormUrl
    subheading {
      subheading
    }
    organizationLogo {
      ${contentfulImage}
    }
    textList {
      raw
    }
    backgroundImage {
      ${contentfulImage}
    }
    backgroundColor
    factoids {
      ${factoids}
    }
  }
`;

const programReferralModule = `
  id
  heading
  headingEmphasis
  referralDescription
  referralCta {
    ${textLink}
  }
  referralList {
    id
    icon
    heading
    subheading
  }
`;

const taxiForm = `
  taxiForm {
    formId
    formGrouping
    title
    subtitle
    postSubmitUrl
  }
`;

const seo = `
  seo {
    pageTitle
    languageCode
    pageDescription {
      pageDescription
    }
    robotsMetaTagContent
    pageImage {
      ${contentfulImage}
    }
  }
`;

const testimonial = `
  id
  image {
    ${contentfulImage}
  }
  attribution
  program
  content {
    raw
  }
  cta {
    ${textLink}
  }
`;

const testimonialModule = `
  title
  testimonials {
    ${testimonial}
  }
`;

const quoteModule = `
  title
  theme
  quote {
    ${testimonial}
  }
`;

const textModule = `
  display
  title
  text {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
      ... on ContentfulMicroText {
        contentful_id
        __typename
        id
        microtext: text {
          body: text
        }
      }
    }
  }
  allowFullWidth
  textAlignment
  cta {
    ${textLink}
  }
  anchorId
  ctaPosition
`;

const prospectusComponentModule = `
  componentName
  theme
`;

const productComparisonGrid = `
  title
  productComparisonCardList {
    price
    title
    time
    description
    schedule
    credentials
    freeToTry
  }
`;

const splitViewComponent = `
  ... on ContentfulEmailFormModule {
    ${emailFormModule}
  }
  ... on ContentfulAccordionTextModule {
    ${accordionTextModule}
  }
  ... on ContentfulIconListModule {
    ${iconListModule}
  }
  ... on ContentfulMediaModule {
    ${mediaModule}
  }
  ... on ContentfulProspectusComponentModule {
    ${prospectusComponentModule}
  }
  ... on ContentfulTextModule {
    ${textModule}
  }
  ... on ContentfulIframeModule {
    ${iframeModule}
  }
`;

const splitView = `
  heading
  leftComponent {
    ...on Node {
      id
      internal {
        type
      }
      ${splitViewComponent}
    }
  }
  rightComponent {
    ...on Node {
      id
      internal {
        type
      }
      ${splitViewComponent}
    }
  }
  reverseOnMobile
  theme
  breakOn
`;

const textListModule = `
  header
  theme
  textListItems {
    id
    header
    description {
      raw
      references {
        ... on ContentfulFootnote {
          ${footnote}
        }
      }
    }
  }
  display
  description {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
  displayListItemsFirst
  leadFormUrl
`;

const dynamicProductListModule = `
  moduleStyle
  query
  filterSpec
  defaultProductType
  maxResult
  cta {
    ... on ContentfulLink {
      ${link}
    }
  }
  theme
`;

const iconModule = `
  heading
  bodyText {
    raw
  }
  icon {
    ${contentfulImage}
  }
  statisticLabel
  statistic
  cta {
    ${textLink}
  }
`;

const linkingModule = `
  id
  linkName
  linkingTags {
    ... on ContentfulLinkingTag {
      id
      tagName
    }
  }
`;

const subcategoryModules = `
... on Node {
  id
  internal {
    type
  }
  ... on ContentfulCardListModule {
    ${cardListModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulCarouselModule {
    ${carouselModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulProductComparisonGrid {
    id
    ${productComparisonGrid}
    internal {
      type
    }
  }
  ... on ContentfulEmailFormModule {
    ${emailFormModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulAccordionTextModule {
    ${accordionTextModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulIconListModule {
    ${iconListModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulMarketingBanner {
    ${marketingBannerInternal}
    id
    internal {
      type
    }
  }
  ... on ContentfulQuoteModule {
    ${quoteModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulTestimonialModule {
    ${testimonialModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulTextModule {
    ${textModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulTextListModule {
    ${textListModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulMediaModule {
    ${mediaModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulProspectusComponentModule {
    ${prospectusComponentModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulSplitViewModule {
    ${splitView}
    id
    internal {
      type
    }
  }
  ... on ContentfulDynamicProductListModule {
    ${dynamicProductListModule}
    id
    internal {
      type
    }
  }
  ... on ContentfulLinkingModule {
    ${linkingModule}
    id
    internal {
      type
    }
  }
}
`;

const admissionsSection = `
  id
  name
  heading
  description {
    raw
  }
  checkList {
    raw
  }
`;

const placementSection = `
  id
  name
  heading
  image {
    ${contentfulImage}
  }
  bodyText {
    raw
    references {
      ... on ContentfulFootnote {
        ${footnote}
      }
    }
  }
`;

const tabSection = `
  title
  anchorId
  modules {
    ... on Node {
      id
      internal {
        type
      }
      ... on ContentfulTextListModule {
        ${textListModule}
      }
      ... on ContentfulFacultyModule {
        ${facultyModule}
      }
      ... on ContentfulAccordionTextModule {
        ${accordionTextModule}
      }
      ... on ContentfulFeaturedProductsModule {
        ${featuredProductsModule}
      }
      ... on ContentfulMediaModule {
        ${mediaModule}
      }
      ... on ContentfulTextModule {
        ${textModule}
      }
      ... on ContentfulProspectusComponentModule {
        ${prospectusComponentModule}
      }
      ... on ContentfulIconModule {
        ${iconModule}
      }
    }
  }
`;

const schoolModule = `
edges {
  node {
    uuid
    updatedAt
    name
    description {
      raw
    }
    descriptionEs {
      raw
    }
    catalogHeading
    catalogHeadingEs
    catalogDescription {
      raw
    }
    catalogDescriptionEs {
      raw
    }
    bannerImageUrl
    logoImageUrl
    freeCourses
  }
}
`;

const ctaContainerModule = `
headline
textContent {
  raw
}
cta {
  label
  url
  isExternal
}
`;

const columnCtaItem = `
... on ContentfulMultiColumnCtaItem {
  title
  text {
    raw
  } 
}
`;

const multiColumnCta = `
  title
  column1 {
    ${columnCtaItem}
  }
  column2 {
    ${columnCtaItem}
  }
  column3 {
    ${columnCtaItem}
  }
`;

const marketingPromotion = `
  name
  bannersByLocation {
    ${marketingBannerInternal}
  }
  language
  pages
  active
`;

module.exports = {
  contentfulImage,
  accordionTextModule,
  awards,
  admissionsSection,
  catalogPageCustomization,
  cardListModule,
  carouselModule,
  dynamicProductListModule,
  emailFormModule,
  executive,
  factoids,
  facultyModule,
  faq,
  featuredProductsModule,
  footnote,
  footnoteModule,
  hero,
  heroInternal,
  iconModule,
  iconListModule,
  iframeModule,
  stickyNavEntryModule,
  marketingBanner,
  marketingBannerInternal,
  marketingPromotion,
  mediaModule,
  multiColumnCta,
  partnershipModule,
  placementSection,
  priceModule,
  productCarouselModule,
  productComparisonGrid,
  productDetailHero,
  programReferralModule,
  prospectusComponentModule,
  quoteModule,
  seo,
  splitView,
  tabSection,
  taxiForm,
  testimonial,
  testimonialModule,
  textModule,
  textListModule,
  subcategoryModules,
  schoolModule,
  ctaContainerModule,
};
